import { configure, defineRule } from 'vee-validate';
import { all as allRules } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import { isValid } from 'iban';

Object.keys(allRules).forEach((rule) => {
  defineRule(rule, allRules[rule]);
});

defineRule('iban', value => {
  if (value === '' || value === null || value === undefined) {
    return true;
  }

  return isValid(value) || 'Lütfen geçerli bir IBAN numarası giriniz';
});

configure({
  generateMessage: localize({
    tr: {
      code: 'tr',
      messages: {
        _default: '{field} alanı geçerli değil',
        alpha: '{field} yalnızca harf içerebilir',
        alpha_dash: '{field} alanı harf ve tire (-) ya da alttan tire (_) içerebilir',
        alpha_num: '{field} yalnızca harf ve rakam içerebilir',
        alpha_spaces: '{field} yalnızca harf boşluk (space) içerebilir',
        between: '{field} 0:{min} ile 1:{max} aralığında olmalıdır',
        confirmed: '{field} doğrulaması hatalı',
        digits: '{field} sayısal ve 0:{length} basamaklı olmalıdır',
        dimensions: '{field} alanı 0:{width} piksel ile 1:{height} piksel arasında olmalıdır',
        email: '{field} alanının geçerli bir e-posta olması gerekir',
        not_one_of: '{field} alanına geçerli bir değer giriniz',
        ext: '{field} alanı geçerli bir dosya olmalıdır',
        image: '{field} alanı resim dosyası olmalıdır',
        integer: '{field} alanı bir tamsayı olmalıdır',
        length: '{field} alanı 0:{length} uzunluğunda olmalıdır',
        one_of: '{field} alanına geçerli bir değer giriniz',
        max: '{field} alanı 0:{length} karakterden fazla olmamalıdır',
        max_value: '{field} alanı 0:{max} ya da daha az bir değer olmalıdır',
        mimes: '{field} geçerli bir dosya olmalıdır',
        min: '{field} alanına en az 0:{length} karakter girilmelidir',
        min_value: '{field} alanı 0:{min} ya da daha fazla bir değer olmalıdır',
        numeric: '{field} alanına sayısal bir değer giriniz',
        regex: '{field} formatı geçersiz',
        required: '{field} alanı gereklidir',
        required_if: '{field} alanı gereklidir',
        size: '{field} alanı 0:{size}KB\'dan daha az olmalıdır',
        url: '{field} geçerli bir URL değil',
        phone: 'Telefon numarası geçerli değil',
        is_not: '{field} için farklı bir değer giriniz.',
      },
    },
  }),
});

setLocale('tr');