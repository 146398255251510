import axios from 'axios';
import { authToken, moveTokensFromCookieToLocaleStorage } from '@/utils/auth-token-utils';

export const api =  axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

const initAxios = (app) => {
  const $notify = app.config.globalProperties.$notify;
  moveTokensFromCookieToLocaleStorage();

  api.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${authToken()}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;
      if ([401, 403].includes(Number(error.response.status)) && !originalRequest._retry) {
        window.location.replace(import.meta.env.VITE_LOGIN_URL);
      } else if (error.response.status === 404 && !originalRequest._retry) {
        originalRequest._retry = true;
      } else if (error.response.status === 422) {
        $notify({
          group: 'error',
          text: error.response.data.detail[0].msg,
          type: 'error',
        });
      }
      return Promise.reject(error);
    }
  );

  return api;
};

const AxiosPlugin = {
  install(app) {
    const instance = initAxios(app);
    app.config.globalProperties.$api = instance;
  },
};

export default AxiosPlugin;
