import amex from '@/assets/images/amex.png'
import mastercard from '@/assets/images/mastercard.png'
import troy from '@/assets/images/troy.png'
import visa from '@/assets/images/visa.png'
import creditCardType from 'credit-card-type'
import moment from 'moment'
import contentStatusEnums from '@/enums/contentStatusEnums'
import interviewStatusEnums from '@/enums/interviewStatusEnums'

export function debounce(fn, delay) {
  var timeoutID = null
  return function() {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function() {
      fn.apply(that, args)
    }, delay)
  }
}

export function convertTimestampToDate(timestamp) {
  return new Date(timestamp * 1000).toLocaleDateString()
}

export function userAvatarName(fullName = '') {
  const [firstName = '', lastName = ''] = fullName.split(' ').filter(Boolean);
  const firstInitial = firstName.length > 0 ? firstName[0] : '';
  const lastInitial = lastName.length > 0 ? lastName[0] : '';
  return `${firstInitial}${lastInitial}`;
}

export function getCreditTypeImage(cardNumber) {
  if (cardNumber) {
    let cardTypes = creditCardType(cardNumber)
    if (cardTypes.length) {
      let firstCardType = cardTypes[0].type
      switch (firstCardType) {
        case 'mastercard':
          return mastercard
        case 'visa':
          return visa
        case 'american-express':
          return amex
        case 'Troy':
          return troy
        default:
          return ''
      }
    }
  } else {
    return ''
  }
}

/**
 * Replaces placeholders in the sales agreement text with actual values.
 * 
 * @param {object} params 
 * @param {string} params.formContent 
 * @param {object} params.user 
 * @param {string} params.price 
 * @param {string} params.price_type 
 */
export function replacePlaceholdersInSalesAgreement({ formContent, user = {}, price = null, price_type = null }) {
  const current_date = new Date().toLocaleDateString();
  const full_name = user.company ? user.company_name : `${user.name || ''} ${user.last_name || ''}`;
  const full_address = `${user.address || ''} ${user.city || ''} ${user.district || ''} ${user.postal_code || ''} ${user.country || ''}`.trim();
  
  return formContent
    .replaceAll('%%full_name%%', full_name)
    .replaceAll('%%full_address%%', full_address)
    .replaceAll('%%phone%%', user.phone || null)
    .replaceAll('%%email%%', user.email || null)
    .replaceAll('%%product_description%%', `₺${price || 0} Tutarında ${price_type || ''}`)
    .replaceAll('%%charged_amount%%', price || 0)
    .replaceAll('%%current_date%%', current_date);
}

export function getKeywordsAsArray(keywords) {
  // keywords = keywords.replace(/\"/gmi, '')
  return keywords.split(',')
}

export function convertTimestampToHours(timestamp) {
  return Math.round(moment.duration(timestamp, 'seconds').asHours())
}

export function paginator(items, page = 1, per_page = 5) {
  const offset = (page - 1) * per_page,
    paginatedItems = items.slice(offset).slice(0, per_page),
    totalPages = Math.ceil(items.length / per_page)

  return {
    page,
    offset,
    paginatedItems,
    totalPages,
    totalItems: items.length,
    previousPage: page - 1 ? page - 1 : null,
    nextPage: totalPages > page ? page + 1 : null,
  }
}

export function isDateBetween(create, start, end) {
  return moment(create * 1000).isBetween(moment(start), moment(end))
}

export function filterByKeyword(item, keyword) {
  return (
    item.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
    item.id == keyword
  )
}

/**
 * @param {Array} array
 * @param {Object} filters
 * @returns {Array}
 */
export function filterArray(array, filters) {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true
      return filters[key](item[key])
    })
  })
}

export function getContentDeadline(timestamp) {
  const now = moment()
  const dateEnd = moment(timestamp * 1000)
  // TODO: Add minutes
  return Math.abs(now.diff(dateEnd, 'hours'))
}

export function getNormalContentDeadline(timestamp) {
  const now = moment()
  const dateEnd = moment(timestamp * 1000)
  // TODO: Add minutes
  return dateEnd.diff(now, 'hours')
}

export function getColorByStatus(status) {
  if (
    contentStatusEnums.editorControl.includes(status) ||
    contentStatusEnums.publisherRevise.includes(status) ||
    contentStatusEnums.editorRevise.includes(status)
  ) {
    return 'bg-purple'
  } else if (contentStatusEnums.continues.includes(status)) {
    return 'bg-pink'
  } else if (contentStatusEnums.cancel.includes(status)) {
    return 'bg-red'
  } else if (contentStatusEnums.publisherControl.includes(status)) {
    return 'bg-orange'
  } else if (contentStatusEnums.done.includes(status)) {
    return 'bg-green'
  } else {
    return 'bg-blue'
  }
}

export function getColorsByStatusForContentDetailHeader(status) {
  switch (status) {
    case 1:
      return {
        bg: 'bg-gray-light',
        color: 'text-primary',
      }
    case 2:
      return {
        bg: 'bg-light',
        color: 'text-dark',
      }
    case 3:
      return {
        bg: 'bg-green-light',
        color: 'text-dark',
      }
    case 4:
      return {
        bg: 'bg-red-light',
        color: 'text-dark',
      }
  }
  // if (contentStatusEnums.editorControl.includes(status)) {
  //   return {
  //     bg: 'bg-gray-light',
  //     color: 'text-white',
  //   }
  // } else if (
  //   contentStatusEnums.publisherRevise.includes(status) ||
  //   contentStatusEnums.editorRevise.includes(status)
  // ) {
  //   return {
  //     bg: 'bg-purple-light',
  //     color: 'text-white',
  //   }
  // } else if (contentStatusEnums.continues.includes(status)) {
  //   return {
  //     bg: 'bg-pink-light',
  //     color: 'text-white',
  //   }
  // } else if (contentStatusEnums.cancel.includes(status)) {
  //   return {
  //     bg: 'bg-red-light',
  //     color: 'text-white',
  //   }
  // } else if (contentStatusEnums.publisherControl.includes(status)) {
  //   return {
  //     bg: 'bg-orange-light',
  //     color: 'text-white',
  //   }
  // } else if (contentStatusEnums.done.includes(status)) {
  //   return {
  //     bg: 'bg-green-light',
  //     color: 'text-white',
  //   }
  // } else {
  //   return {
  //     bg: 'bg-blue-light',
  //     color: 'text-white',
  //   }
  // }
}

export function getColorsByHour(deadline) {
  let deadlineAsHour = getNormalContentDeadline(deadline)

  if (deadlineAsHour <= 12) {
    return {
      bgColor: 'bg-red-light',
      color: 'text-red',
    }
  } else if (deadlineAsHour > 12 && deadlineAsHour <= 24) {
    return {
      bgColor: 'bg-orange-light',
      color: 'text-orange',
    }
  } else {
    return {
      bgColor: 'bg-light',
      color: 'text-primary',
    }
  }
}

export function getColorAndBgColorByContentStatus(status) {
  if (contentStatusEnums.editorControl.includes(status)) {
    return {
      bgColor: 'bg-light',
      bgColorHarder: 'bg-gray',
      color: 'text-gray',
    }
  } else if (contentStatusEnums.editorRevise.includes(status)) {
    return {
      bgColor: 'bg-purple-light',
      bgColorHarder: 'bg-purple',
      color: 'text-purple',
    }
  } else if (contentStatusEnums.publisherRevise.includes(status)) {
    return {
      bgColor: 'bg-pink-light',
      bgColorHarder: 'bg-pink',
      color: 'text-pink',
    }
  } else if (contentStatusEnums.continues.includes(status)) {
    return {
      bgColor: 'bg-teal-light',
      bgColorHarder: 'bg-teal',
      color: 'text-teal',
    }
  } else if (contentStatusEnums.cancel.includes(status)) {
    return {
      bgColor: 'bg-red-light',
      bgColorHarder: 'bg-red',
      color: 'text-red',
    }
  } else if (contentStatusEnums.publisherControl.includes(status)) {
    return {
      bgColor: 'bg-orange-light',
      bgColorHarder: 'bg-orange',
      color: 'text-orange',
    }
  } else if (contentStatusEnums.done.includes(status)) {
    return {
      bgColor: 'bg-green-light',
      bgColorHarder: 'bg-green',
      color: 'text-green',
    }
  } else {
    return {
      bgColor: 'bg-blue-light',
      bgColorHarder: 'bg-blue',
      color: 'text-blue',
    }
  }
}

export function getColorsByInterviewStatus(status) {
  if (status === 1 || status === 2 || status === 3 || status === 4) {
    return 'text-blue'
  } else if (status === 5 || status===12) {
    return 'text-orange'
  } else if (status === 6) {
    return 'text-green'
  } else if (status === 7) {
    return 'text-red'
  } else {
    return 'text-dark'
  }
}

export function getColorAndBgColorByInterviewStatus(status) {
  if (interviewStatusEnums.continues.includes(status)) {
    return {
      color: 'text-blue',
      bgColor: 'bg-blue-light',
    }
  } else if (interviewStatusEnums.control === status) {
    return {
      color: 'text-orange',
      bgColor: 'bg-orange-light',
    }
  } else if (interviewStatusEnums.approved === status) {
    return {
      color: 'text-green',
      bgColor: 'bg-green-light',
    }
  } else if (interviewStatusEnums.rejected === status) {
    return {
      color: 'text-red',
      bgColor: 'bg-red-light',
    }
  } else {
    return {
      color: 'text-dark',
      bgColor: 'bg-light',
    }
  }
}

String.prototype.turkishToLower = function() {
  var string = this
  // "ğĞıİöÖüÜşŞçÇ", "gGiIoOuUsScC"
  var letters = {
    ğ: 'g',
    Ğ: 'G',
    ı: 'i',
    İ: 'I',
    ö: 'o',
    Ö: 'O',
    ü: 'u',
    Ü: 'U',
    ş: 's',
    Ş: 'S',
    ç: 'c',
    Ç: 'C',
  }
  string = string.replace(/(([ğĞıİöÖüÜşŞçÇ]))+/g, function(letter) {
    return letters[letter]
  })
  return string.toLowerCase()
}

export function checkIfKeywordMatches(keyword, contentAsString) {
  // compute content to turkish lower case
  if (contentAsString) {
    let computedContentAsString = turkish_to_english(contentAsString)
    computedContentAsString = computedContentAsString.replace(/[!,\'*)@#%(&$_?.^"+\/”“]/gmi, '')
    let rg = /\b/

    let keywordWordArr = turkish_to_english(keyword)
      .replace(/[!,\'*)@#%(&$_?.^"+\/”“]/gim, '')
      .split(' ')
    
    rg = `${rg}${keywordWordArr.join(" ").trim()}([^\\s]+)?\\b\\s`

    // remove extra \ chars and sum with the other part of the word
    rg = rg.slice(0, 3) + rg.slice(4)
    rg = rg.slice(0, 0) + rg.slice(1)

    // to remove the extra \\s at the end
    rg = rg.replace(/\\s+$/, '')

    const regex = new RegExp(rg, 'gi')

    return computedContentAsString.match(regex) ? true : false
  }
}

const fallbackCopyTextToClipboard = text => {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function() {
      console.log('Async: Copying to clipboard was successful!')
    },
    function(err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

export const turkish_to_english = data => {
  return data
    .replace(/Ğ/gim, 'g')
    .replace(/Ü/gim, 'u')
    .replace(/Ş/gim, 's')
    .replace(/I/gim, 'i')
    .replace(/İ/gim, 'i')
    .replace(/Ö/gim, 'o')
    .replace(/Ç/gim, 'c')
    .replace(/ğ/gim, 'g')
    .replace(/ü/gim, 'u')
    .replace(/ş/gim, 's')
    .replace(/ı/gim, 'i')
    .replace(/ö/gim, 'o')
    .replace(/ç/gim, 'c')
}
